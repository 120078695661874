import UPDATE_ORGANIZATION from '@/gql/mutation/organization/updateOrganization.gql'

export default {
  methods: {
    async updateOrganization(id, variables = {}) {
      await this.$apollo.mutate({
        mutation: UPDATE_ORGANIZATION,
        variables: {
          updateOrganizationId: id,
          ...variables,
        },
      })
    },
  },
}
