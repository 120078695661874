<template>
  <b-overlay
    :show="isFetching"
    spinner-variant="primary"
  >
    <b-card title="Restaurants Management">
      <h6>Your Organizations</h6>
      <b-list-group flush>
        <restaurants-organization
          v-for="organization in organizations"
          :key="organization.id"
          :organization="organization"
        />
      </b-list-group>
      <hr class="mb-2">
      <h6>Your Restaurants</h6>
      <b-list-group
        v-if="restaurant"
        flush
      >
        <b-list-group-item
          v-for="restaurantItem in restaurants"
          :key="restaurantItem.id"
        >
          <b-row>
            <b-col>
              {{ restaurantItem.name }}
              <template v-if="restaurantItem.nickname">
                ({{ restaurantItem.nickname }})
              </template>
            </b-col>
            <b-col cols="auto">
              <feather-icon
                :class="[
                  {
                    'text-danger': !deletingRestaurantId && !isRestaurantCreating,
                    'feather-disabled': deletingRestaurantId || isRestaurantCreating
                  },
                ]"
                icon="TrashIcon"
                size="20"
                role="button"
                @click="onClickDeleteRestaurant(restaurantItem.id)"
              />
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row class="align-items-center">
            <b-col>
              <b-form-input
                v-model="restaurantName"
                :disabled="isSubscriptionInactive || isRestaurantCreating || !!deletingRestaurantId"
                size="sm"
                placeholder="New Restaurant Name"
                @keyup.enter="onKeyupEnterCreateRestaurant"
              />
            </b-col>
            <b-col cols="auto">
              <b-spinner
                v-if="isRestaurantCreating"
                variant="primary"
                class="spinner-20"
              />
              <feather-icon
                v-else
                class="text-primary"
                icon="PlusCircleIcon"
                size="20"
                role="button"
                @click="onClickCreateRestaurant"
              />
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <modal-confirm-delete
      v-model="isModalConfirmDelete"
      :title="confirmDeleteModalTitle"
      :is-message="false"
      @delete="onConfirmDelete"
    />
    <modal-confirm-charge
      v-if="restaurantName"
      v-model="isModalConfirmCharge"
      @confirmed="onConfirmedCharge"
    >
      <template slot="title">
        <i18n path="restaurantsCreatingNewRestaurantTitle">
          <template v-slot:slot>
            <b>{{ restaurantName }}</b>
          </template>
        </i18n>
      </template>
      <template slot="text">
        <i18n path="restaurantsCreatingNewRestaurantText">
          <template v-slot:slot>
            <b>{{ $t('Confirm') }}</b>
          </template>
          <template v-slot:slot2>
            <b>{{ newRestaurantSubscriptionPrice }}</b>
          </template>
        </i18n>
      </template>
    </modal-confirm-charge>
    <modal-stripe
      :visible="isModalSubscription"
      :title="`${$t('Payment Data')}`"
      :button-text="`${$t('Subscribe')}`"
      :display-coupon="false"
      :chosen-plan-monthly-pay="0"
      :chosen-plan-currency-symbol="'$'"
      @cancel="isModalSubscription = false"
      @success="onSuccessModalSubscription"
    />
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import mixinGqlRestaurant from '@/mixins/gql/restaurant'
import RestaurantsOrganization from '@/components/RestaurantsOrganization.vue'
import ModalConfirmDelete from '@/components/modal/ModalConfirmDelete.vue'
import ModalConfirmCharge from '@/components/modal/ModalConfirmCharge.vue'
import ModalStripe from '@/components/modal/ModalStripe.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import START_SUBSCRIPTION from '@/gql/mutation/billing/startSubscription.gql'

export default {
  name: 'Restaurants',
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BSpinner,
    BFormInput,
    BListGroup,
    BListGroupItem,
    RestaurantsOrganization,
    ModalConfirmDelete,
    ModalConfirmCharge,
    ModalStripe,
  },
  mixins: [
    mixinGqlRestaurant,
  ],
  data() {
    return {
      isFetching: true,
      deletingRestaurantId: null,
      restaurantName: '',
      isRestaurantCreating: false,
      isModalConfirmDelete: false,
      isModalConfirmCharge: false,
      isModalSubscription: false,
    }
  },
  computed: {
    ...mapGetters({
      organizations: 'organizations/organizations',
      restaurants: 'restaurant/restaurants',
      restaurant: 'restaurant/restaurant',
      subscription: 'billing/subscription',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      paymentMethods: 'billing/paymentMethods',
    }),
    confirmDeleteModalTitle() {
      if (!this.deletingRestaurantId) {
        return ''
      }

      const restaurant = this.restaurants.find(i => i.id === this.deletingRestaurantId)
      const slot = restaurant.nickname ? `${restaurant.name} (${restaurant.nickname})` : restaurant.name
      return this.$t('restaurantDeleteModalTitle', { slot })
    },
    subscriptionPeriodData() {
      if (this.subscription && this.subscription.interval && this.subscription.productDetails) {
        return this.subscription.productDetails.periods[this.subscription.interval]
      }
      return null
    },
    newRestaurantSubscriptionPrice() {
      if (!this.subscriptionPeriodData) {
        return ''
      }
      return `${this.subscriptionPeriodData.currencySymbol}${this.subscriptionPeriodData.monthlyPay}`
    },
  },
  watch: {
    restaurant() {
      this.checkData()
    },
    isModalConfirmDelete(newValue) {
      if (!newValue) {
        this.deletingRestaurantId = null
      }
    },
  },
  created() {
    this.checkData()
  },
  methods: {
    ...mapActions({
      createRestaurant: 'restaurant/createRestaurant',
      setRestaurants: 'restaurant/setRestaurants',
      getPaymentMethods: 'billing/getPaymentMethods',
    }),
    checkData() {
      if (this.restaurant) {
        this.isFetching = false
      }
    },
    onKeyupEnterCreateRestaurant() {
      this.validateAndShowModalConfirmCharge()
    },
    onClickCreateRestaurant() {
      this.validateAndShowModalConfirmCharge()
    },
    validateAndShowModalConfirmCharge() {
      if (!this.restaurantName || this.deletingRestaurantId) {
        return
      }

      this.isModalConfirmCharge = true
    },
    onClickDeleteRestaurant(id) {
      if (id === this.restaurant.id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: this.$t('notificationErrorDeletingCurrentRestaurant'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.deletingRestaurantId = id
      this.isModalConfirmDelete = true
    },
    async onConfirmDelete() {
      await this.deleteRestaurant(this.deletingRestaurantId)
      this.setRestaurants(this.restaurants.filter(i => i.id !== this.deletingRestaurantId))

      this.deletingRestaurantId = null
      this.isModalConfirmDelete = false
    },
    async onConfirmedCharge() {
      if (!this.paymentMethods) {
        await this.getPaymentMethods()
      }

      this.isModalConfirmCharge = false
      this.isModalSubscription = true
    },
    async onSuccessModalSubscription({
      country,
      cardholderName,
      billingEmailAddress,
      token,
      coupon,
    }) {
      this.isRestaurantCreating = true

      try {
        const restaurant = await this.createRestaurant({ name: this.restaurantName })
        await this.$apollo.mutate({
          mutation: START_SUBSCRIPTION,
          variables: {
            restaurantId: restaurant.id,
            billingEmailAddress,
            cardholderName,
            qty: 1,
            country,
            stripePriceId: this.subscriptionPeriodData.stripePriceId,
            stripeProductId: this.subscriptionPeriodData.stripeProductId,
            token,
            coupon,
          },
        })

        this.restaurantName = ''
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isRestaurantCreating = false
        this.isModalSubscription = false
      }
    },
  },
}
</script>
