<template>
  <b-modal
    :visible="value"
    centered
    hide-header
    hide-footer
    @hide="$emit('input', false)"
    @hidden="onHiddenModal"
  >
    <div class="pt-1 pb-1">
      <h3 class="mb-0 text-center">
        <slot name="title" />
      </h3>
      <p class="mt-2 mb-2">
        <slot name="text" />
      </p>
      <b-form-checkbox
        v-model="isAcceptedCharge"
        inline
        :disabled="isConfirming"
        :state="stateCharge"
        @click.native="stateCharge = null"
      >
        {{ $t('modalConfirmChargeCheckboxProrate') }}
      </b-form-checkbox>
      <br><br>
      <b-form-checkbox
        v-model="isAcceptedTermsAndPolicy"
        inline
        :disabled="isConfirming"
        :state="stateTermsAndPolicy"
        @click.native="stateTermsAndPolicy = null"
      >
        <i18n path="modalConfirmChargeCheckboxTermsAndPolicy">
          <template v-slot:slot>
            <a
              :href="`https://sporkinc.com/${localeForWebsite}/terms-of-service`"
              target="_blank"
            >{{ $t('modalConfirmChargeCheckboxTermsAndPolicyLinkTerms') }}</a>
          </template>
          <template v-slot:slot2>
            <a
              :href="`https://sporkinc.com/${localeForWebsite}/privacy-policy`"
              target="_blank"
            >{{ $t('modalConfirmChargeCheckboxTermsAndPolicyLinkPolicy') }}</a>
          </template>
        </i18n>
      </b-form-checkbox>
      <div class="d-flex align-items-center justify-content-center mt-2 pt-1">
        <b-button
          block
          variant="outline-primary"
          :disabled="isConfirming"
          @click="$emit('input', false)"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          block
          variant="primary"
          class="d-flex justify-content-center ml-1 mt-0"
          :disabled="isConfirming"
          @click="onClickConfirm"
        >
          <b-spinner
            v-if="isConfirming"
            class="d-flex"
            small
          />
          <template v-else>
            {{ $t('Confirm') }}
          </template>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormCheckbox,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalConfirmCharge',
  components: {
    BModal,
    BFormCheckbox,
    BButton,
    BSpinner,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isAcceptedCharge: false,
      stateCharge: null,

      isAcceptedTermsAndPolicy: false,
      stateTermsAndPolicy: null,

      isConfirming: false,
    }
  },
  computed: {
    ...mapGetters({
      locale: 'main/locale',
    }),
    localeForWebsite() {
      if (this.locale === 'uk') {
        return 'ua'
      }
      return this.locale
    },
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.isConfirming = false
      }
    },
  },
  methods: {
    onHiddenModal() {
      this.isAcceptedCharge = false
      this.stateCharge = null

      this.isAcceptedTermsAndPolicy = false
      this.stateTermsAndPolicy = null
    },
    onClickConfirm() {
      if (!this.isAcceptedCharge) {
        this.stateCharge = false
      }
      if (!this.isAcceptedTermsAndPolicy) {
        this.stateTermsAndPolicy = false
      }
      if (!this.isAcceptedCharge || !this.isAcceptedTermsAndPolicy) {
        return
      }

      this.isConfirming = true
      this.$emit('confirmed')
    },
  },
}
</script>
