<template>
  <b-list-group-item>
    <b-row class="align-items-center">
      <b-col>
        <b-form-input
          v-model.trim="name"
          :disabled="isSaving"
          size="sm"
          placeholder="Organization Name"
        />
      </b-col>
      <b-col cols="auto">
        <b-spinner
          v-if="isSaving"
          variant="primary"
          class="spinner-20"
        />
        <feather-icon
          v-else
          :class="[
            { 'text-primary': name && name !== organizationName },
            { 'feather-disabled': !name || name === organization.name },
          ]"
          icon="SaveIcon"
          size="20"
          role="button"
          @click="onClickSave"
        />
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
import {
  BListGroupItem,
  BRow,
  BCol,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import mixinGqlOrganization from '@/mixins/gql/organization'

export default {
  name: 'RestaurantsOrganization',
  components: {
    BListGroupItem,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
  },
  mixins: [
    mixinGqlOrganization,
  ],
  props: {
    organization: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      name: '',
      isSaving: false,
    }
  },
  computed: {
    ...mapGetters({
      organizations: 'organizations/organizations',
      restaurants: 'restaurant/restaurants',
    }),
    organizationName() {
      return this.organization.name
    },
  },
  watch: {
    organizationName: {
      handler(newValue) {
        this.name = newValue
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setOrganizations: 'organizations/setOrganizations',
      setRestaurants: 'restaurant/setRestaurants',
    }),
    async onClickSave() {
      if (!this.name || this.name === this.organization.name) {
        return
      }

      this.isSaving = true

      await this.updateOrganization(this.organization.id, { name: this.name })

      this.setOrganizations(this.organizations.map(i => (i.id === this.organization.id
        ? { ...i, name: this.name }
        : i
      )))
      this.setRestaurants(this.restaurants.map(i => (i.organization.id === this.organization.id
        ? { ...i, organization: { ...i.organization, name: this.name } }
        : i
      )))

      this.isSaving = false
    },
  },
}
</script>
